
/*###################
###               ###
### FLY FILM FEST ###
###               ###
###################*/

  body.ctct-flyfilmtheme.mobile {
    header#masthead {
      padding-top: 82px;
      nav#site-navigation {
        height: 82px;

      }
    }
  }




  body.ctct-flyfilmtheme {
    div.multisite-nav-wrapper {
      position: fixed;
      z-index: 1001;
      left: 0;
      right: 0;
      div.multisite-nav-inner {

      }
    }


    header#masthead {

      nav#site-navigation {
        // top: 42px;
        @media screen and (max-width: $mobile-max) {
          padding-top: 45px;
        }
        @media screen and (min-width: $desktop-sm-min) {
          // height: 100px;
          height: 130px;
        }
        &>div.page-width {
          div.logo,
          div.menu-toggle {
            z-index: 9999;
          }
          div.logo {
            @media screen and (max-width: $mobile-max) {
              top: -30px;
            }
          }
          div.menu-toggle {
            @media screen and (max-width: $mobile-max) {
              // top: -15px;
              top: 2px;
              left: auto;
              right: 30px;
              margin-left: auto;
            }
          }
          &>div.menu-main-menu-container {
                  
            @media screen and (max-width: $mobile-max) {
              top: 34px;
            }
            @media screen and (min-width: $desktop-sm-min) {
              top: 15px;
            }
            @media screen and (min-width: $desktop-md-min) {
              line-height: 100px;
            }
          }
          &>div.social {
            @media screen and (max-width: $mobile-max) {
              right: 60px;
              margin-top: 0;
            }
          }
          a.btn.buy-tickets,
          div.social {
            margin-top: 30px;
          }
        }
      }
    }
  }

  body.ctct-flyfilmtheme.scrolled {
    div.multisite-nav-wrapper {
      top: 0;
    }
  }
