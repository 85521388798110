
.shopify-hide-xs {
  @media screen and (max-width: 749px) {
    display: none!important;
  }
}

body.ff-store {
  div#StickyBar:not(.sticky--open) {
    div.multisite-nav-wrapper {
      display: none!important;
    }
  }
  div#StickyBar.sticky--open {
    div.multisite-nav-wrapper {
      display: none!important;
      @media screen and (min-width: 750px) {
        display: flex!important;
        position: absolute;
        z-index: 6;

      }
    }
    nav#StickyNav {
      @media screen and (min-width: 750px) {
        padding-top: 40px;
      }
    }
  }

  div#shopify-section-header {
    &>div.multisite-nav-wrapper {
      position: absolute;
      // left: 0;
      // right: 0;
      z-index: 1;
      div.multisite-nav-inner {
        position: relative;
      }
    }
    &>div.multisite-nav-wrapper {
      & ~ header.site-header {
        @media screen and (max-width: 749px) {
          padding-top: 45px;
        }
        div.grid--table {
          div.backfusionmag-wrapper {
            @media (min-width: $desktop-sm-min) {
              margin-top: 20px;
            }
          }
          div.logo-wrapper {
            h1.site-header__logo {
              @media (min-width: $desktop-sm-min) {
                // margin-top: -60px;
              }
              img {
                max-width: 275px;
                @media (min-width: $desktop-sm-min) {
                // margin-top: -60px;
              }
              }
            }
          }
        }
      }
    }
  }
}



#SiteNavSearchCart {
  @media (min-width: $desktop-md-min) {
    padding-top: 20px!important;
  }
  
}

.site-header__upper {
  padding-bottom: 0!important;
}