$mobile-max: 767px;
$desktop-sm-min: 768px;
$desktop-md-min:  992px;
$desktop-lg-min: 1200px;

$header-bg-grey: #D8D8D8;
$grey-dark:     #7F7F7F;

$universal-header-font: 'Lato', sans-serif;

.hide-desktop {
  @media (min-width: $desktop-sm-min) {
    display: none!important;
  }
}


/*###################################################
### Restricts to logged in for FlyFusionMag & IF4 ###
###################################################*/

body {
  div.multisite-nav-wrapper {
    // display: none;
  }

}
body.logged-in {
  div.multisite-nav-wrapper {
    // display: block;
  }
}
body.ff-store {
  div.multisite-nav-wrapper {
    // display: block;
  }
}

body.ctct-flyfusiontheme div.multisite-nav-wrapper + header + section#main-content {
  div#home-splash {
    // top: -182px!important;
  }
}


/*########################################################
### IMPORTS - Visual Changes Restricted To "Logged In" ###
########################################################*/
body.ctct-flyfilmtheme,
body.ctct-flyfusiontheme,
body.ff-store {
  @import 'universal-header';
}

@import 'universal-header-if4';
@import 'universal-header-mag';
@import 'universal-header-store';