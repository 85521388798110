

/*####################
###                ###
### FLY FUSION MAG ###
###                ###
####################*/
 body.ctct-flyfusiontheme {
    div.multisite-nav-wrapper {
      position: fixed;
      z-index: 1001;
      left: 0;
      right: 0;
      div.multisite-nav-inner {

      }
    }
}
