@media (min-width: 768px) {
  .hide-desktop {
    display: none !important; } }

/*###################################################
### Restricts to logged in for FlyFusionMag & IF4 ###
###################################################*/
/*########################################################
### IMPORTS - Visual Changes Restricted To "Logged In" ###
########################################################*/
body.ctct-flyfilmtheme,
body.ctct-flyfusiontheme,
body.ff-store {
  /*####################
### Shared Styling ###
####################*/
  /* Need to add in selector & breakpoint for Store */ }
  body.ctct-flyfilmtheme div.multisite-nav-wrapper,
  body.ctct-flyfusiontheme div.multisite-nav-wrapper,
  body.ff-store div.multisite-nav-wrapper {
    height: 42px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    z-index: 500; }
    body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner,
    body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner,
    body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner {
      max-width: 50%;
      flex: 0 0 50%;
      display: flex;
      flex-flow: row nowrap;
      position: relative;
      margin-top: -1px; }
      @media screen and (min-width: 768px) {
        body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner,
        body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner,
        body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner {
          max-width: 50%;
          flex: 0 0 50%; } }
      body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon,
      body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon + nav,
      body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon,
      body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon + nav,
      body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon,
      body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon + nav {
        display: inline-block; }
      body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon,
      body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon,
      body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon {
        max-width: 35px;
        margin-left: 30px; }
        @media screen and (min-width: 768px) {
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon {
            margin-left: 20px; } }
        @media screen and (min-width: 992px) {
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon {
            margin-left: 45px; } }
        body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon img,
        body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon img,
        body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner span#multisite-ff-icon img {
          display: block;
          width: 100%;
          height: auto; }
      body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav:hover li + ul,
      body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav:hover li + ul,
      body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav:hover li + ul {
        opacity: 1;
        visibility: visible;
        display: block; }
      body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger,
      body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger,
      body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger {
        display: inline-block; }
        @media screen and (min-width: 768px) {
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger {
            display: none; } }
        body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger:hover + ul,
        body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger:hover + ul,
        body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger:hover + ul {
          opacity: 1;
          visibility: visible;
          display: block; }
        body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a,
        body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a,
        body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a {
          color: black;
          font-weight: 900;
          font-size: 14px;
          vertical-align: middle; }
          @media screen and (min-width: 768px) {
            body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a,
            body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a,
            body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a {
              font-size: inherit; } }
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a:hover,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a:hover,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a:hover {
            color: #7F7F7F;
            text-decoration: none; }
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a::after,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a::after,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav li#multisite-nav-trigger a::after {
            content: " ";
            display: inline-block;
            height: 10px;
            width: 10px;
            background-image: url("../images/icons/icon_dropdown_grey.svg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            margin-left: 5px;
            margin-right: 5px; }
      body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul,
      body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul,
      body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul {
        margin: 0;
        display: none;
        padding: 0 .5rem .5rem .5rem; }
        @media screen and (max-width: 767px) {
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul {
            background-color: #D8D8D8;
            position: absolute;
            left: auto;
            right: 0;
            max-width: 86%;
            width: 100%; } }
        @media screen and (min-width: 475px) {
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul {
            max-width: 91%; } }
        @media screen and (min-width: 768px) {
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul {
            display: block;
            max-width: none; } }
        body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li,
        body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li,
        body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li {
          display: block;
          text-transform: uppercase;
          font-size: 15px; }
          @media screen and (min-width: 768px) {
            body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li,
            body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li,
            body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li {
              display: inline-block;
              font-size: 10px; } }
          @media screen and (min-width: 992px) {
            body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li,
            body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li,
            body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li {
              font-size: 14px; } }
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:before,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:before,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:before {
            font-size: inherit; }
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li a,
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li a,
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li a {
            color: #7F7F7F;
            font-size: inherit;
            font-weight: 900;
            line-height: 1; }
        body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:first-of-type > a,
        body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:first-of-type > a,
        body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:first-of-type > a {
          color: black; }
        body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:first-of-type)::before,
        body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:first-of-type)::before,
        body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:first-of-type)::before {
          color: #7F7F7F; }
          @media screen and (min-width: 768px) {
            body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:first-of-type)::before,
            body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:first-of-type)::before,
            body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:first-of-type)::before {
              content: "//";
              margin-right: 3.5px; } }
          @media screen and (min-width: 992px) {
            body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:first-of-type)::before,
            body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:first-of-type)::before,
            body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:first-of-type)::before {
              margin-right: 7.5px; } }
        @media screen and (min-width: 768px) {
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:last-of-type),
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:last-of-type),
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:last-of-type) {
            margin-right: 3.5px; } }
        @media screen and (min-width: 992px) {
          body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:last-of-type),
          body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:last-of-type),
          body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner nav.multisite-nav > ul > li:not(:last-of-type) {
            margin-right: 7.5px; } }
  body.ctct-flyfilmtheme div.multisite-nav-wrapper,
  body.ctct-flyfusiontheme div.multisite-nav-wrapper,
  body.ff-store div.multisite-nav-wrapper {
    border-top: 10px solid #D8D8D8;
    font-family: "Lato", sans-serif; }
    body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner,
    body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner,
    body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner {
      background-image: url("../images/nav_multi_bg-xs.svg");
      background-position: left center;
      background-size: auto 100%;
      background-repeat: no-repeat; }
      @media screen and (min-width: 376px) {
        body.ctct-flyfilmtheme div.multisite-nav-wrapper div.multisite-nav-inner,
        body.ctct-flyfusiontheme div.multisite-nav-wrapper div.multisite-nav-inner,
        body.ff-store div.multisite-nav-wrapper div.multisite-nav-inner {
          background-image: url("../images/nav_multi_bg-sm.svg");
          background-size: cover; } }

/*###################
###               ###
### FLY FILM FEST ###
###               ###
###################*/
body.ctct-flyfilmtheme.mobile header#masthead {
  padding-top: 82px; }
  body.ctct-flyfilmtheme.mobile header#masthead nav#site-navigation {
    height: 82px; }

body.ctct-flyfilmtheme div.multisite-nav-wrapper {
  position: fixed;
  z-index: 1001;
  left: 0;
  right: 0; }

@media screen and (max-width: 767px) {
  body.ctct-flyfilmtheme header#masthead nav#site-navigation {
    padding-top: 45px; } }

@media screen and (min-width: 768px) {
  body.ctct-flyfilmtheme header#masthead nav#site-navigation {
    height: 130px; } }

body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width div.logo,
body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width div.menu-toggle {
  z-index: 9999; }

@media screen and (max-width: 767px) {
  body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width div.logo {
    top: -30px; } }

@media screen and (max-width: 767px) {
  body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width div.menu-toggle {
    top: 2px;
    left: auto;
    right: 30px;
    margin-left: auto; } }

@media screen and (max-width: 767px) {
  body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width > div.menu-main-menu-container {
    top: 34px; } }

@media screen and (min-width: 768px) {
  body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width > div.menu-main-menu-container {
    top: 15px; } }

@media screen and (min-width: 992px) {
  body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width > div.menu-main-menu-container {
    line-height: 100px; } }

@media screen and (max-width: 767px) {
  body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width > div.social {
    right: 60px;
    margin-top: 0; } }

body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width a.btn.buy-tickets,
body.ctct-flyfilmtheme header#masthead nav#site-navigation > div.page-width div.social {
  margin-top: 30px; }

body.ctct-flyfilmtheme.scrolled div.multisite-nav-wrapper {
  top: 0; }

/*####################
###                ###
### FLY FUSION MAG ###
###                ###
####################*/
body.ctct-flyfusiontheme div.multisite-nav-wrapper {
  position: fixed;
  z-index: 1001;
  left: 0;
  right: 0; }

@media screen and (max-width: 749px) {
  .shopify-hide-xs {
    display: none !important; } }

body.ff-store div#StickyBar:not(.sticky--open) div.multisite-nav-wrapper {
  display: none !important; }

body.ff-store div#StickyBar.sticky--open div.multisite-nav-wrapper {
  display: none !important; }
  @media screen and (min-width: 750px) {
    body.ff-store div#StickyBar.sticky--open div.multisite-nav-wrapper {
      display: flex !important;
      position: absolute;
      z-index: 6; } }

@media screen and (min-width: 750px) {
  body.ff-store div#StickyBar.sticky--open nav#StickyNav {
    padding-top: 40px; } }

body.ff-store div#shopify-section-header > div.multisite-nav-wrapper {
  position: absolute;
  z-index: 1; }
  body.ff-store div#shopify-section-header > div.multisite-nav-wrapper div.multisite-nav-inner {
    position: relative; }

@media screen and (max-width: 749px) {
  body.ff-store div#shopify-section-header > div.multisite-nav-wrapper ~ header.site-header {
    padding-top: 45px; } }

@media (min-width: 768px) {
  body.ff-store div#shopify-section-header > div.multisite-nav-wrapper ~ header.site-header div.grid--table div.backfusionmag-wrapper {
    margin-top: 20px; } }

body.ff-store div#shopify-section-header > div.multisite-nav-wrapper ~ header.site-header div.grid--table div.logo-wrapper h1.site-header__logo img {
  max-width: 275px; }

@media (min-width: 992px) {
  #SiteNavSearchCart {
    padding-top: 20px !important; } }

.site-header__upper {
  padding-bottom: 0 !important; }
