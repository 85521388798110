


/*####################
### Shared Styling ###
####################*/


div.multisite-nav-wrapper {
  height: 42px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  z-index: 500;
  div.multisite-nav-inner {
    // max-width: 25%;
    max-width: 50%;
    // flex: 0 0 25%;
    flex: 0 0 50%;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    margin-top: -1px;
    @media screen and (min-width: 375px) {
      // max-width: 40%;
      // flex: 0 0 40%;
    }
    @media screen and (min-width: $desktop-sm-min) {
      // padding-left: 100px;
      max-width: 50%;
      flex: 0 0 50%;
    }

    span#multisite-ff-icon,
    span#multisite-ff-icon+nav {
      display: inline-block;
    }
    span#multisite-ff-icon {
      max-width: 35px;
      margin-left: 30px;
      @media screen and (min-width: $desktop-sm-min) {
        margin-left: 20px;
      }
      @media screen and (min-width: $desktop-md-min) {
        margin-left: 45px;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    nav.multisite-nav {
      &:hover {
        li + ul {
           opacity: 1;
          visibility: visible;
          display: block;
        }
      }
      li#multisite-nav-trigger {
        display: inline-block;

        @media screen and (min-width: $desktop-sm-min) {
          display: none;
        }
        &:hover + ul {
          opacity: 1;
          visibility: visible;
          display: block;
        }
        a {
          // color: $grey-dark;
          color: black;
          font-weight: 900;
          font-size: 14px;
          vertical-align: middle;
          @media screen and (min-width: $desktop-sm-min) {
            font-size: inherit;
          }
          &:hover {
            color: $grey-dark;
            text-decoration: none;
          }
          &::after {
            content: " ";
            display: inline-block;
            height: 10px;
            width: 10px;
            background-image: url('../images/icons/icon_dropdown_grey.svg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            margin-left: 5px;
            margin-right: 5px;

          }
        }
      }
      &>ul {
        margin: 0;
        // opacity: 0;
        // visibility: hidden;
        display: none;
        padding: 0 .5rem .5rem .5rem;
        @media screen and (max-width: $mobile-max) {
          background-color: $header-bg-grey;
          position: absolute;
          left: auto;
          right: 0;
          // max-width: 190px;
          max-width: 86%;
          width: 100%;
          // left: 9%;
          // width: calc(100% - 9%);
        }
        @media screen and (min-width: 475px) {
          max-width: 91%;
        }
        @media screen and (min-width: $desktop-sm-min) {
          display: block;
          max-width: none;
        }
        &>li {
          display: block;
          text-transform: uppercase;
          font-size: 15px;
          @media screen and (min-width: $desktop-sm-min) {
            display: inline-block;
            font-size: 10px;
          }
          @media screen and (min-width: $desktop-md-min) {
            font-size: 14px;
          }
          @media screen and (min-width: $desktop-lg-min) {
            // font-size: 15px;
          }
          &:before {
            font-size: inherit;
          }
          a {
            color: $grey-dark;
            font-size: inherit;
            font-weight: 900;
            line-height: 1;
          }
        }
        &>li:first-of-type {
          &>a {
            color: black;;
          }
        }
        &>li:not(:first-of-type) {
          &::before {
            color: $grey-dark;
            @media screen and (min-width: $desktop-sm-min) {
              content: "//";
              margin-right: 3.5px;
            }
            @media screen and (min-width: $desktop-md-min) {
              margin-right: 7.5px;
            }
          }
        }
        &>li:not(:last-of-type) {
          @media screen and (min-width: $desktop-sm-min) {
            margin-right: 3.5px;
          }
          @media screen and (min-width: $desktop-md-min) {
            margin-right: 7.5px;
          }
        }
      }
    }
    nav.multisite-nav:focus-within {
      ul {
        // display: block!important;
      }
    }
  }
}


 /* Need to add in selector & breakpoint for Store */
div.multisite-nav-wrapper {
  border-top: 10px solid $header-bg-grey;
  font-family: $universal-header-font;
  div.multisite-nav-inner {
    background-image: url('../images/nav_multi_bg-xs.svg');
    background-position: left center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    @media screen and (min-width: 376px) {
      background-image: url('../images/nav_multi_bg-sm.svg');
      // background-size: 100% auto
      background-size: cover;
    }
  }
}
